@import "../../_Styles/variables.scss";
@import "../../_Styles/mixins.scss";

.top-nav {
  box-shadow: 0 1px 7px $base-font-color;
  background-color: #fff;
  position: sticky;
  top: 0;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;
  z-index: 1001;
  &.scrolled {
    height: 90px;
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $base-padding-hz;
    position: relative;

    .logo {
      display: flex;
      align-items: center;

      img {
        width: 150px;
        height: auto;
        @include sm {
          width: 200px;
        }
      }
    }

    // Hamburger/Cross Icon Styling
    .menu-icon {
      display: block;
      cursor: pointer;
      position: relative;
      width: 30px;
      height: 30px;
      z-index: 2000;

      // Hamburger icon lines
      .hamburger,
      .cross {
        width: 100%;
        height: 2px;
        background-color: $base-font-color;
        position: absolute;
        top: 50%;
        left: 0;
        transition: 0.3s;
      }

      // Hamburger (default)
      .hamburger {
        &:before,
        &:after {
          content: "";
          position: absolute;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $base-font-color;
        }
        &:before {
          top: -8px;
        }
        &:after {
          top: 8px;
        }
      }

      // Cross (when menu is open)
      .cross {
        transform: rotate(45deg);

        &:before {
          transform: rotate(-90deg);
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: $base-font-color;
        }
        &:after {
          content: none;
        }
      }
    }

    // Menu list styling
    .nav-links {
      position: absolute;
      top: 100%;
      right: 0;
      width: 100%;
      background-color: white;
      z-index: 1000;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
      transform: translateY(-10px);
      transition: all 0.4s ease-in-out;

      // When menu is open (small screens)
      &.open {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }

      nav ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;

        li {
          margin: 10px 0;

          a {
            text-decoration: none;
            color: $base-font-color;
            font-size: $base-font-size;
            text-align: center;
            padding: 10px 0;
            display: block;
            width: 100%;
            @include hover-color-transition;

            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }
  }

  // Larger screen (tablet and desktop)
  @include lg {
    .nav-container {
      .menu-icon {
        display: none; // Hide hamburger menu icon on larger screens
      }

      .nav-links {
        display: flex; // Instead of block, ensure the menu is flex for desktop
        position: static;
        width: auto;
        opacity: 1;
        visibility: visible;
        transform: none;
        box-shadow: none;
        transition: none; // Remove transition for larger screens

        nav ul {
          flex-direction: row; // Horizontal layout for desktop
          align-items: center;

          li {
            margin-left: 20px;
            margin-top: 0px;
            margin-bottom: 0px;

            a {
              font-size: $base-font-size + 2;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
