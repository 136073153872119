@import "../../_Styles/variables.scss";
@import "../../_Styles/mixins.scss";

.footer {
  background-color: $base-font-color;
  color: $secondary-color;
  text-align: center;
  padding: $base-padding-hz;
  margin-top: 40px;
  a {
    color: $secondary-color;
    text-decoration: none;
    &:hover {
      color: $primary-color;
      @include hover-color-transition;
    }
  }
  .site-container {
    max-width: $xl;
    margin: auto;
    .footer-container {
      display: flex;
      flex-direction: row;
      .col-1,
      .col-2,
      .col-3 {
        flex: 1;
      }
      .col-2 {
        img {
          height: 40px;
          margin: 10px;
          transform: scale(1);
          transition: transform 0.5s ease, filter;
          cursor: pointer;
          &:hover {
            filter: brightness(0) saturate(100%) invert(84%) sepia(80%)
              saturate(1483%) hue-rotate(297deg) brightness(100%) contrast(90%);
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .details {
    font-size: $base-font-size - 2;
    .like-the-site {
      font-size: $base-font-size;
    }
  }
}
