// @import url("https://fonts.googleapis.com/css?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import "_Styles/variables.scss";

// <link rel="preconnect" href="https://fonts.googleapis.com">
// <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
// <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet">

body {
  font-family: "Montserrat", sans-serif;
  color: $base-font-color;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  .site-container {
    max-width: $xl;
    width: 100%;
    margin: auto;
  }
}
