//Font-sizes

$base-font-size: 16px;
$h1-font-size: 26px;
$h2-font-size: 24px;
$h3-font-size: 20px;
$h4-font-size: 18px;

$base-font-weight: 500;
$large-font-weight: 700;
$small-font-weight: 300;

$base-font-color: #333;
$primary-color: #ff9f8c;
$secondary-color: #fff;

$base-padding-horizontal: 0 20px;
$base-padding-vertical: 20px 0;
$base-padding-hz: 20px;

$base-margin-horizontal: 0 20px;
$base-margin-vertical: 20px 0;

//Breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
