@import "variables.scss";

//Breakpoints

@mixin sm {
  @media only screen and (min-width: $sm) {
    @content;
  }
}

@mixin md {
  @media only screen and (min-width: $md) {
    @content;
  }
}

@mixin lg {
  @media only screen and (min-width: $lg) {
    @content;
  }
}

@mixin xl {
  @media only screen and (min-width: $xl) {
    @content;
  }
}

//Fonts

@mixin h1 {
  font-size: $h1-font-size;
  font-weight: $large-font-weight;
  padding: $base-padding-vertical;
  text-transform: uppercase;
}

@mixin h2 {
  font-size: $h2-font-size;
  font-weight: $large-font-weight;
  padding: $base-padding-vertical;
  text-transform: uppercase;
}

@mixin h3 {
  font-size: $h3-font-size;
  font-weight: $small-font-weight;
  padding: $base-padding-vertical;
  text-transform: uppercase;
}

//Buttons

@mixin primary-btn {
  margin: $base-margin-vertical;
  color: $base-font-color;
  background-color: $secondary-color;
  border: 1px solid $base-font-color;
  padding: 10px 20px;
  text-decoration: none;
  transition: all 0.3s;
  text-transform: uppercase;
  display: inline-block;
  &:hover {
    background-color: $primary-color;
    color: $base-font-color;
  }
}

@mixin hover {
  color: $primary-color;
}

@mixin hover-color-transition {
  transition: color 0.3s;
}
