@import "../../_Styles/variables.scss";
@import "../../_Styles/mixins.scss";

.search-bar {
  width: auto;
  position: relative;
  display: flex;
  justify-content: center;
  @include sm {
    width: 100%;
  }
  @include lg {
    width: auto;
  }

  input {
    width: 60%;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ddd;
    border-radius: 5px;
    outline: none;
    @include lg {
      width: 100%;
    }

    &:focus {
      border-color: #007bff;
    }
  }

  .search-results {
    position: absolute;
    top: 50px;
    width: 120%;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 999;
    @include sm {
      width: 60%;
    }
    @include lg {
      width: 200%;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        padding: 10px;
        border-bottom: 1px solid #eee;

        &:last-child {
          border-bottom: none;
        }

        a {
          text-decoration: none;
          color: black;

          &:hover {
            color: #007bff; /* Change color on hover */
          }

          span {
            background-color: transparent;
          }
        }
      }
    }
  }
}
