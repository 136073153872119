@import "../../_Styles/mixins.scss";
@import "../../_Styles/variables.scss";

.unsubscribe-intro {
  max-width: $xl;
  margin: auto;
  padding: $base-padding-horizontal;
  h1 {
    @include h1;
  }
  a {
    color: $base-font-color;
    @include hover-color-transition;
    &:hover {
      color: $primary-color;
    }
  }
}

.form-container-unsubscribe {
  background-color: #eff2f7;
}
