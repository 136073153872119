@import "../../_Styles/variables.scss";
@import "../../_Styles/mixins.scss";

.team-details {
  max-width: $xl;
  margin: auto;
  padding: $base-padding-horizontal;

  .linkedin-icon {
    img {
      margin-bottom: 10px;
      height: 35px;
      width: auto;
      transition: transform 0.5s ease, filter;
      filter: brightness(0) saturate(100%) invert(25%) sepia(75%)
        saturate(5682%) hue-rotate(199deg) brightness(92%) contrast(92%);
      transform: scale(1);
      &:hover {
        filter: brightness(0) saturate(100%) invert(84%) sepia(80%)
          saturate(1483%) hue-rotate(297deg) brightness(100%) contrast(90%);
        transform: scale(1.1);
      }
    }
  }

  h1 {
    @include h1;
  }

  h2 {
    @include h2;
    margin: 0;
  }

  h3 {
    @include h3;
  }

  a {
    color: $base-font-color;
    @include hover-color-transition;
    &:hover {
      color: $primary-color;
    }
  }

  img.header-image {
    width: 100%;
    margin-bottom: 20px;
    height: 300px;
    object-fit: cover;
    height: auto;
    @include sm {
      height: 400px;
      width: 400px;
    }
  }

  .address-container {
    p {
      margin: 0;
    }
  }

  .date-container {
    p {
      margin: 0;
    }
  }

  .primary-btn {
    @include primary-btn;
  }
}
