@import "../../_Styles/variables.scss";
@import "../../_Styles/mixins.scss";

.news-details {
  max-width: $xl;
  margin: auto;
  padding: $base-padding-horizontal;

  h1 {
    @include h1;
  }

  h2 {
    @include h2;
  }

  h3 {
    @include h3;
  }

  a {
    color: $base-font-color;
    @include hover-color-transition;
    &:hover {
      color: $primary-color;
    }
  }

  img.header-image {
    width: 100%;
    margin-bottom: 20px;
    height: 300px;
    object-fit: cover;
    @include md {
      height: 600px;
    }
  }

  .address-container {
    p {
      margin: 0;
    }
  }

  .date-container {
    p {
      margin: 0;
    }
  }

  .primary-btn {
    @include primary-btn;
  }
}
