@import "../../_Styles/variables.scss";
@import "../../_Styles/mixins.scss";

.full-screen-loader {
  position: relative; /* Use relative so it affects the layout */
  min-height: 100vh; /* Ensure it takes the full viewport height */
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid $primary-color; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
